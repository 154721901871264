import React, { useEffect, useState } from 'react';
import TranslationService from "../../../services/TranslationService";
import { useSelector } from 'react-redux';
import partner_1 from './../../../assets/images/ardyPartners/1.jpeg';
import partner_2 from './../../../assets/images/ardyPartners/2.jpeg';
import partner_3 from './../../../assets/images/ardyPartners/3.jpeg';
import partner_4 from './../../../assets/images/ardyPartners/4.jpeg';
import partner_5 from './../../../assets/images/ardyPartners/5.jpeg';
import partner_6 from './../../../assets/images/ardyPartners/6.jpeg';
import partner_7 from './../../../assets/images/ardyPartners/7.jpeg';
import partner_8 from './../../../assets/images/ardyPartners/8.png';
import useWindowSize from '../../../components/hooks/usewindowSize';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';


export default function Partners() {

	const windowSize = useWindowSize();
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const { translations } = useSelector(state => state.translation);

	const partnersList = [partner_1, partner_2, partner_3, partner_4, partner_5, partner_6, partner_7, partner_8];

	const getSliderPerView = () => {
		if (windowSize.width > 768) {
			return 5;
		} else if (windowSize.width < 768 && windowSize.width > 576) {
			return 3
		} else if (windowSize.width < 576) {
			return 1
		}
	}

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return (translationService ? <section className="section">
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					{
						language === "en" ?
							<h2 className="h1 mb-4">
								{translationService.translate("TR_OUR")}{" "}
								<b className="h1-m">
									{translationService.translate("TR_PARTNERS")}
								</b>
							</h2>
							: <h2 className="h1 mb-4">
								{translationService.translate("TR_PARTNERS")}
							</h2>
					}
					<p>
						{translationService.translate("TR_PARTNERS_SUB_TITLE")}
					</p>
				</div>
				<div className='col-12'>
					<div className='card' style={{ border: "none", borderRadius: "16px", padding: "0 48px" }}>
						<div className='partners-swiper'>
							<div className="py-3">
								<Swiper
									slidesPerView={getSliderPerView()}
									spaceBetween={30}
									centeredSlides={true}
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									loop={true}
									modules={[Autoplay, Pagination, Navigation]}
									className="mySwiper"
								>
									{
										partnersList.map((item, index) => {
											return <SwiperSlide key={index}>
												<img src={item} className='partner-logo' />
											</SwiperSlide>
										})
									}

								</Swiper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section> : null);
}
