import React, { useCallback, useEffect, useState, useRef } from "react";
import Auxiliary from "../../hoc/Auxiliary";
import arrowLeft from "./../../assets/icons/arrow/arrow-left-main.svg";
import arrowRightLight1 from "./../../assets/icons/arrow/arrow-right-light-l.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertService from "../../services/AlertService";
import TranslationService from "../../services/TranslationService";
import { addSpinner, removeSpinner } from "../../store/actions/spinner";
import { v4 as uuidv4 } from "uuid";
import SkeletonComponent from "../../components/Skeleton/SkeletonComponent";
import Carousel from "nuka-carousel";
import axios from "axios";
import moment from "moment";
import useWindowSize from "../../components/hooks/usewindowSize";
import Parser from 'html-react-parser'

const logo = [
	"https://www.mintformations.co.uk/blog/wp-content/uploads/2020/05/shutterstock_583717939.jpg",
	"https://1000logos.net/wp-content/uploads/2022/10/Noodles-and-Company-Logo-1995.png",
	"https://www.freepnglogos.com/uploads/company-logo-png/company-logo-transparent-png-19.png",
];

export default function Event() {
	const windowSize = useWindowSize();
	const { eventId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { language } = useSelector(state => state.language);
	const { spinners } = useSelector(state => state.spinners);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [event, setEvent] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (!eventId) {
			navigate(`/${language}`);
		}
		getEvents();
		return () => {
			setEvent(null);
		};
	}, [language]);

	const getTitleFontSize = (title) => {
		if (!title) { return {} }
		if (windowSize.width > 991) {
			if (title.length < 30) {
				return { fontSize: "50px", lineHeight: "55px" }
			} else if (title.length < 60) {
				return { fontSize: "45px", lineHeight: "50px" }
			} else if (title.length < 70) {
				return { fontSize: "40px", lineHeight: "45px" }
			} else if (title.length < 80) {
				return { fontSize: "35px", lineHeight: "40px" }
			} else {
				return { fontSize: "30px", lineHeight: "35px" }
			}
		}
		return {}
	}

	const getEvents = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		// https://api.woodscenter.am/api/event/${eventId}
		// http://3.128.39.93:3005/api/event
		axios
			.get(`https://api.woodscenter.am/api/event/${eventId}`, {
				headers: {
					"X-Content-Language": language || "am",
				},
			})
			.then(response => {
				if (
					response &&
					response.data &&
					response.data.events &&
					response.data.events.length
				) {
					const event = response.data.events.find(el => el._id === eventId);
					setEvent(event);
				} else if (response && response.data) {
					setEvent(response.data);
				}
				extractSpinner(spinnerId);
				scrollToTop();
			})
			.catch(error => getFail(error, spinnerId));
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const drawDescription = (desc) => {
		console.log(desc);
		let _desc = desc.replace("\n▫️", "<br />");
		return _desc
	}

	const getFail = (error, spinnerId) => {
		error && AlertService.alert("error", error);
		spinnerId && extractSpinner(spinnerId);
	};

	const setSpinner = useCallback(spinner => {
		dispatch(addSpinner(spinner));
	}, []);

	const extractSpinner = useCallback(spinner => {
		setTimeout(() => {
			dispatch(removeSpinner(spinner));
		}, 500);
	}, []);

	if (spinners && spinners.length) {
		return (
			<section className="section">
				<div className="container-fluid overflow-hidden p-0">
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={300}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={400}
								titles={0}
								descs={0}
							/>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={700}
								titles={0}
								descs={0}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
	return (
		!!translationService &&
		!!event && (
			<Auxiliary>
				<section className="section pb-0">
					<div className="container-fluid">
						{
							<Link className="mb-3 d-block" to={`/${language}/events`}>
								<img src={arrowLeft} alt="arrow-left-main" />
								<b>{translationService.translate("TR_EVENTS")}</b>
							</Link>
						}
					</div>
					<div className="ardy--course-container">
						<div className="ardy--marketing-card" style={{ backgroundColor: "#F4F4F4" }}>
							<div
								className="ardy--card-body w-100"
								style={{ backgroundColor: "#F4F4F4" }}>

								{/* <h2 className="h1 max-line-2" title={"Event title goes here"}>
									{event.name}
								</h2> */}

								<h2 className="h1" title={event.name} style={getTitleFontSize(event.name)}>
									{event.name}
								</h2>
							</div>

							{/* <div
								className="ardy--marketing-image"
								style={{ backgroundImage: `url(${event.thumbnail})`, height:"400px" }}
							/> */}
							<div className="d-flex justify-content-center w-100">
								<img src={event.thumbnail} style={{ height: "300px" }} />
							</div>
						</div>
						<div className="ardy--course-card">
							<div className="d-md-flex">
								{/* <div className="ardy--course-card-title mt-md-0 mt-3">
									<div className="d-flex align-items-center gap-5 ml-4 flex-wrap">
										<div>
											<span>{translationService.translate("TR_DATE")}</span>
											<h3 className="h3">
												{moment(event.schedule).format("DD.MM.YYYY")}
											</h3>
										</div>

										<div>
											<span>{translationService.translate("TR_TIME")}</span>
											<h3 className="h3">
												{moment(event.schedule).format("hh:mm A")}
											</h3>
										</div>
										<div>
											<span>{translationService.translate("TR_FLOOR")}</span>
											<h3 className="h3">{event.venue?.floor}</h3>
										</div>
										<div>
											<span>{translationService.translate("TR_VENUE")}</span>
											<h3 className="h3">{event.venue?.name}</h3>
										</div>
									</div>
								</div> */}

								<div className="d-lg-flex align-items-center ms-auto gap-5">
									<h5
										style={{
											color: " var(--Success, #169F00)",
											fontFamily: "Suisse Int'l",
											fontSize: 24,
											fontStyle: "normal",
											fontWeight: 500,
											lineHeight: "normal",
										}}>
										{event.display_price
											? `${event?.display_price} AMD`
											: translationService.translate("TR_FREE")}
									</h5>
									{!event.is_past_event && !!event.registration_link && (
										<a
											href={event.registration_link}
											target="_blank"
											className="button primary--btn arrow--btn mt-lg-0 mt-2 text-nowrap">
											{translationService.translate("TR_REGISTER_FOR_EVENT")}
											<img src={arrowRightLight1} alt="arrow-right-l" />
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section pt-0">
					<div className="container-fluid position-relative">
						<div className="row  align-content-center justify-content-center">
							<div
								className="col-lg-9 ardy--paddingX-block"
								style={{ paddingTop: "5rem" }}>
								<div className={`row mt-5`}>
									<div className="col-12">
										{!!event.name && (
											<h2 className="h2-m mb-4">{`${event.name}!`}</h2>
										)}
										{!!event.description && <span
											dangerouslySetInnerHTML={{
												__html: event.description.replace(/\n/g, "<br/>"),
											}}></span>}
									</div>
								</div>

								{/* <div
									style={{
										width: 94,
										height: 1,
										backgroundColor: "#1E2329",
									}}
									className="col-2 mt-5"></div>
								{event.what_to_expect && !!event.what_to_expect.length && (
									<div className={`row  mt-5 `}>
										<div className="col-12">
											<h2 className="h2-m">
												{translationService.translate("TR_EXPECT")}
											</h2>
											<ul className="mt-2 pl-4">
												{event.what_to_expect.map((expect, i) => (
													<li key={i}>{expect}</li>
												))}
											</ul>
										</div>
									</div>
								)} */}

								{event.detailed_schedule &&
									!!event.detailed_schedule.length && (
										<div className={`row mt-5 `}>
											<div className="col-12">
												<h2 className="h2-m mb-3">
													{translationService.translate("TR_SCHEDULE")}
												</h2>
												<div>
													<table className="table table-striped table-responsive shadow-lg rounded ardy-event-tabel overflow-hidden">
														<thead>
															<tr>
																<th
																	scope="col"
																	style={{
																		paddingLeft: "32px",
																		paddingRight: "32px",
																	}}>
																	{translationService.translate("TR_TIME")}
																</th>
																<th scope="col">
																	{translationService.translate("TR_ACTIVITY")}
																</th>
															</tr>
														</thead>
														<tbody>
															{event.detailed_schedule.map(
																(schedule, index) => (
																	<tr key={index + "a"}>
																		<td
																			scope="row max-line-1"
																			className="w-50"
																			style={{
																				paddingLeft: "32px",
																				paddingRight: "32px",
																			}}>{`${schedule.start_time} - ${schedule.end_time}`}</td>
																		<td className="">{schedule.name}</td>
																	</tr>
																),
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									)}
								{event.speakers && !!event.speakers.length && (
									<div className="row">
										<div className="col-lg-8">
											<div className="row">
												<div className="col-12">
													<h2 className="h2-m my-5">
														{translationService.translate("TR_SPEAKERS")}
													</h2>
												</div>
												{event.speakers.map((speaker, i) => (
													<div className="col-12" key={i}>
														<div className="d-md-flex justify-content-center">
															<img
																className="ardy--user-avatar-xl me-5"
																src={speaker?.avatar}
																alt="avatar"
															/>
															<div className="">
																<h4 className="h2 mt-md-0 mt-2">
																	{speaker.name}
																</h4>
																<b className="" style={{ fontSize: "24px" }}>
																	{speaker.position}
																</b>
																<p className="mt-3">{speaker?.description}</p>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								)}
								{/* {!event.is_past_event && !!event.registration_link && (
									<div className="row">
										<div className="col-12">
											<h2
												className="h2-m mt-5 fw-500"
												style={{ fontSize: "24px" }}>
												{translationService.translate("TR_REGISTER_FOR_EVENT")}
											</h2>
										</div>
										<div className="col-12">
											<div className="d-md-flex justify-content-between flex-wrap align-items-baseline">
												<p className="mt-3 fw-500" style={{ fontSize: "20px" }}>
													{translationService.translate(
														"TR_DONT_MISS_BY_REGISTERING",
													)}
												</p>
												<a
													className="link--btn primary--btn header--btn"
													href={event.registration_link}
													target="_blank">
													{translationService.translate(
														"TR_REGISTER_FOR_EVENT",
													)}
												</a>
											</div>
										</div>
									</div>
								)} */}

								{event.sponsors && !!event.sponsors.length && (
									<div className="row">
										<div className="col-12">
											<h2
												className="h2-m mt-5 fw-500"
												style={{ fontSize: "24px" }}>
												{translationService.translate("TR_SPONSORS")}
											</h2>
										</div>
										<div className="col-12">
											<div className="carousel-inner">
												<div className="ardy--standart-carousel mt-3">
													<Carousel
														wrapAround={true}
														slidesToShow={3}
														autoplay={true}
														className="custom-slide-current"
														autoplayInterval={5000}
														renderCenterLeftControls={({ previousSlide }) =>
															null
														}
														renderCenterRightControls={({ nextSlide }) => null}
														renderBottomCenterControls={({ currentSlide }) =>
															null
														}>
														{event.sponsors.map((sponsor, i) => (
															<a key={i} href={sponsor.website} target="_blank">
																<img
																	className="ardy-event-carousel-img"
																	style={{ mixBlendMode: "multiply" }}
																	src={sponsor.logo}
																	alt="/"
																/>
															</a>
														))}
													</Carousel>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			</Auxiliary>
		)
	);
}